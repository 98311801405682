import React, { ReactElement, useEffect } from 'react';
import { AppProps, NextWebVitalsMetric } from 'next/app';
import { useRouter } from 'next/router';
import { metricsReport } from '../headless/utils';
import { trackPage } from '../utils/gtm';
import '../styles/global.scss';

if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        titleColor: 'green',
        diffNameColor: 'aqua',
        trackAllPureComponents: true,
        logOnDifferentValues: false,
    });
}

export function reportWebVitals(metrics: NextWebVitalsMetric): void {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
        metricsReport(metrics);
    }
}

function MyApp({ Component, pageProps }: AppProps): ReactElement {
    const router = useRouter();
    useEffect(() => {
        router.events.on('routeChangeComplete', trackPage);
        return () => {
            router.events.off('routeChangeComplete', trackPage);
        };
    }, [router.asPath, router.defaultLocale, router.events, router.locale]);

    return <Component {...pageProps} />;
}

export default MyApp;
