export function trackPage(currentUrl: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataLayer = (window as any).dataLayer || [];
    dataLayer.push({
        event: 'ga.page',
        pagePath: currentUrl,
    });
}

export function trackFormSend() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataLayer = (window as any).dataLayer || [];
    dataLayer.push({
        event: 'form_sent',
    });
}

export function trackButtonClick(text?: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataLayer = (window as any).dataLayer || [];
    dataLayer.push({
        event: 'click_button',
        text,
    });
}
